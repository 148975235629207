import React, { useState, useEffect, useRef } from 'react';
import './Offers.css'
import Effect from "../../Components/background/vanta.net"
import * as THREE from 'vanta/vendor/three.r119.min.js';

export default function Offers() {
  window.THREE = THREE
  const zero = useRef(null);
  const [vantaEffect, setVantaEffect] = useState(0)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(Effect({
        THREE,
        el: zero.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,



      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])


  return (

    <div className="Page" >
      <div id="GreetingCompound" ref={zero} >
        <div class="WorkDiv">
          <h1 class="WorkTitle" >
            what is our<br />
            <span style={{ color: "#FC766A" }}> {"<offer>"}</span>?<br />
          </h1>
          <span id="GreetingSubText">
            scroll down to find out <br />
            about our <span style={{ color: "#FC766A" }}>{"<offers>"}</span> and <span style={{ color: "#FC766A" }}>{"<prices>"}</span> .
          </span>
          <img onClick={() => window.scrollTo({left:0, top:document.body.scrollHeight,behavior: 'smooth'})} class="Arr bouncetop" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/thin-chevron-arrow-bottom-icon.svg")}></img>
        </div>
      </div>
      <div id="GreetingCompound" style={{ paddingTop: "100px!important" }} >
        <div class="WorkDiv" style={{ paddingTop: "5em" }}>
          <h1 class="WorkTitle" style={{ fontSize: "70px", paddingBottom: "70px" }} >
            we offer 3 different packages to fit your <span style={{ color: "#FC766A" }}>{"<needs>"}</span>
          </h1>
          <section class="page-contain">
          <a href="/contact" class="data-card" >
            <h4>Starts at</h4>
            <h3>499€</h3>
            <h4>Starter</h4>
            <p>We will implement a predefined AI/Datascience Project. Data, Structure and Benchmarks is given by your company.<br/><br/></p>
            <span class="link-text">
              Contact me
              <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD" />
              </svg>
            </span>
          </a>
          <a href="/contact" class="data-card">
            <h4>Starts at</h4>
            <h3 >999€</h3>
            <h4>Professional</h4>
            <p>We will help you define an AI project and implement it. We will support data acquisition and develop Structures and Benchmarks specifically for your case.</p>
            <span class="link-text">
              Contact me
              <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD" />
              </svg>
            </span>
          </a>
          <a href="/contact" class="data-card">
            <h4>Starts at</h4>
            <h3>1999€</h3>
            <h4>Enterprise</h4>
            <p>We will look at your businesses processes and help you find use cases for AI and implement these. Contains everything included in the previous packages. </p>
            <span class="link-text">
              Contact me
              <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD" />
              </svg>
            </span>
          </a>
          </section>
        </div>
      </div>
    </div>

  )
}
