import React, { useState, useEffect, useRef } from 'react';
import Graphics3D from './3DGraphics/3DGraphics';
import './Work.css'
import Effect from "vanta/src/vanta.net"
import * as THREE from 'vanta/vendor/three.r119.min.js';
export default function Work() {
  window.THREE = THREE
  const zero = useRef(null);
  const [vantaEffect, setVantaEffect] = useState(0)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(Effect({
        THREE,
        el: zero.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0xFC766A,
        backgroundColor: 0x101820,


      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (

    <div className="Page" >
      <div id="GreetingCompound" ref={zero} >
        <div class="WorkDiv">
          <h1 class="WorkTitle" >
            how can we <br />
            <span style={{ color: "#FC766A" }}> {"<help>"}</span> you?<br />
          </h1>
          <span id="GreetingSubText">
            scroll down to submit your request so we can <br />
            help you <span style={{ color: "#FC766A" }}>{"<explore>"}</span>, <span style={{ color: "#FC766A" }}>{"<automate>"}</span> and <span style={{ color: "#FC766A" }}>{"<innovate>"}</span> <br />with aritificial intelligence.
          </span>
          <img onClick={() => window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })} class="Arr bouncetop" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/thin-chevron-arrow-bottom-icon.svg")}></img>
        </div>
      </div>
      <div id="GreetingCompound" style={{ paddingTop: "100px!important" }} >
        <div class="WorkDiv" style={{ paddingTop: "5em" }}>
          <h1 id="tt"class="WorkTitle" style={{ fontSize: "70px", paddingBottom: "70px" }} >
           contact us to schedule an <span style={{ color: "#FC766A" }}>{"<appointment>"}</span>
          </h1>
          <section id="pc" class="page-contain">


          <div class="data-card" >
          <h3>infos</h3>
            <h4>our contact information</h4>
            <p style={{display: "flex",  flexWrap: "wrap"}}>
            <img class="icon"  src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/pin-48.svg")}></img>
            <span class="lbl">
            Bommersheimer Weg 35a, 61348 Bad Homburg
            </span>
            <br/>
            <br/>
            <br/>
            <img class="icon"  src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/mail-2569.svg")}></img>
            <span  class="lbl">
            +49 152 09840345
            </span>
            <br/>

            <img class="icon"  src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/phone-call-svgrepo-com.svg")}></img>
            <span class="lbl">
            henry.hommel@gmx.de
            </span>
            </p>
            
           

          </div>


          <div id="con" class="data-card">
          <h3 >contact</h3>
            <h4>Fill out the form</h4>
            <p>            <label for="email">telephone</label>
                <input class="in" type="text" id="email" name="e-mail" placeholder="your telephone" />
            <label for="email">e-mail</label>
                <input class="in" type="text" id="email" name="e-mail" placeholder="your e-mail" />
            <label for="body">message</label>
                <textarea id="body" name="body" placeholder="your message"></textarea>
                </p>
            <span id="t" class="link-text" style={{textAlign:"center",padding:"0.5em",borderRadius:"1em",backgroundColor:"white"}}>
              Send Form!
            </span>
          </div>



          <div class="data-card">
          <h3>process</h3>
            <h4>moving forward</h4>
            <p>Send us a Message and we will reach out to you with a meeting request. In Summary we will take in your request, evaluate the request, if we cam think we can fufill your request we send an offer. </p>
            <br/>
          </div>

          </section>
        </div>
      </div>
      {/* <div id="GreetingCompound" style={{ paddingTop: "100px!important" }} >


        <div id="special" className="ContentCard hvr-grow-shadow" style={{ marginBottom: "4%" }} >
          <div id="wrapper">
            <div id="left">
                <h1 style={{color:"white"}}>Get in Touch</h1>
            </div>
            <div id="right">

            </div>
          </div>
        </div>
        <div class="container">
          <form action="mailto:henry.hommel@gmx.com"  enctype="text/plain">
              <label for="data">what type of data do you want to work with?</label>
              <select class="in" id="data" name="data">
                  <option value="text">text</option>
                  <option value="image">image</option>
                  <option value="numerical">numerical</option>
                  <option value="multiple">multiple</option>
                </select>
                <label for="what">what is the task about?</label>
                <select class="in" id="what" name="what">
                  <option value="exp">exploratory data Analysis</option>
                  <option value="image">AI development</option>
                  <option value="numerical">numerical</option>
                  <option value="multiple">multiple</option>
                </select>
                <label for="body">what specifically would you like to do with your data?</label>
                <textarea id="body" name="body" placeholder="">Write something...</textarea>
                <label for="fname">first name</label>
                <input class="in" type="text" id="fname" name="firstname" placeholder="your name.." />

                <label for="lname">last ame</label>
                <input class="in" type="text" id="lname" name="lastname" placeholder="your last name.." />

                <label for="email">e-mail</label>
                <input class="in" type="text" id="email" name="e-mail" placeholder="your e-mail.." />

                <label for="country">where are you from?</label>
                <select class="in" id="country" name="country">
                  <option value="EU">europe</option>
                  <option value="NA">usa/canada</option>
                  <option value="SA">south america</option>
                  <option value="Australia">australia</option>
                  <option value="Asia">asia</option>
                </select>          
                <input style={{width:"10%",height:"15%"}}class="in" type="submit" value="Submit" />
            </form>
        </div>
      </div> */}
    </div>


  )
}
