import React from 'react';
import './playground.css'
import { imageClassification } from "./classify"
import { waitUntil } from 'async-wait-until';
export default function Imageclass() {
    const waitForImg = async (event) => {

        var image = document.getElementById('output');
        image.src = URL.createObjectURL(document.getElementById("avatar").files[0]);
        console.log(image.width)

    }
    const handleClick = async (event) => {

        var image = document.getElementById('output');
        // expected output: 123


        // while (image.width==0) {
        //     setTimeout(function() {
        //     console.log('wait');
        //   }, 10);}
        // setTimeout(function() {
        //     console.log('wait');
        //     console.log(image.width)
        //   }, 500);
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);
        var data = context.getImageData(0, 0, image.width, image.height);
        console.log(data)
        var res = await imageClassification(data);
        document.getElementById("idkkk").innerHTML = JSON.stringify(res);
        return






    }
    return (
        <>
            <div className="playgrounddiv">
                <div style={{ float:"left"}}>
                <label >Choose an Image to Classify: </label><br/><br/>
                <label id="avatar2"for="avatar">Pick Image</label><br/>
                <input type="file"
                    id="avatar" name="avatar"
                    onChange={(event)=>waitForImg(event)}
                    accept="image/png, image/jpeg"></input>

                
                </div>

                <div style={{ float:"left",height: "400px", width: "400px" }} >
                    <img id="output" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/NationalGeographic_2572187_square.jpg")} />
                    
                </div>
                <span id="buttontry" onClick={(event) => handleClick(event)} class="link-text" style={{ position: "absolute", bottom: "5", textAlign: "center", padding: "0.5em", borderRadius: "1em", backgroundColor: "white" }}>
                    Try it!
                </span>
                <div id="idkkk" style={{ float:"left"}}>

                </div>
                {/* <div class="fields">
                    <input type="radio" value="blue" name="gender" defaultChecked/> Class 1
                    <input type="radio" value="red" name="gender" /> Class 2
                    </div>
                    <div class="fields">
                    <button class ='btn'onClick={()=>clear()}>clear</button>
                    <button class ='btn' onClick={()=>pred()}>predict</button>
                    </div> */}


            </div>
        </>
    )
}