import React, { useState, useEffect, useRef } from 'react';
import Graphics3D from './3DGraphics/3DGraphics';
import './About.css'
import Effect from "../../Components/background/vanta.net"

import NETS from "vanta/dist/vanta.net.min.js"
import * as THREE from 'vanta/vendor/three.r119.min.js';
import p5 from 'p5' 
export default function About() {
  window.THREE = THREE 
  const zero = useRef(null);
  const [vantaEffect, setVantaEffect] = useState(0)
  useEffect(() => {
    if (!vantaEffect) { 
      setVantaEffect(  Effect({ 
        THREE,   
        el: zero.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,



      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])
  // const [vantaEffect, setVantaEffect] = useState(0)
  // useEffect(() => {
  //   if (!vantaEffect) { 
  //     setVantaEffect(  NETS({
  //       THREE,   
  //       el: zero.current,
  //       mouseControls: true,
  //       touchControls: true,
  //       gyroControls: false,
  //       minHeight: 200.00,
  //       minWidth: 200.00,
  //       scale: 1.00,
  //       scaleMobile: 1.00,
  //       color: 0xb05358,
  //       size: 1.40,
  //       backgroundColor: 0x111111,
  //       points:10
  //     }))
  //   }
  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy()
  //   }
  // }, [vantaEffect])

  return (

    <div className="Page" >
      <div id="GreetingCompound" ref={zero} >
        <div class="WorkDiv">
          <h1 class="WorkTitle" >
            who is<br />
            <span style={{ color: "#FC766A" }}> {"<flowsense>"}</span>?<br />
          </h1>
          <span id="GreetingSubText">
            scroll down to find out <br />
            about <span style={{ color: "#FC766A" }}>{"<who>"}</span> we are.
          </span>
          <img onClick={() => window.scrollTo({left:0, top:document.body.scrollHeight,behavior: 'smooth'})} class="Arr bouncetop" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/thin-chevron-arrow-bottom-icon.svg")}></img>
        </div>
      </div>
      <div id="GreetingCompound" style={{ paddingTop: "100px!important" }} >
        <div class="WorkDiv" style={{ paddingTop: "5em" }}>
          <h1 class="WorkTitle" style={{ fontSize:"70px", paddingBottom: "70px" }} >
            flowsense is a freelancer based in <span style={{ color: "#FC766A" }}>{"<germany>"}</span> 
          </h1>
        <div style={{fontSize:"25px",lineHeight: "160%"}}>
          hi, im <span style={{ color: "#FC766A" }}>{"<henry>"}</span>. i am a 23 year old programmer from frankfurt.<br/>
          i studied <span style={{ color: "#FC766A" }}>{"<economics>"}</span> at goethe university frankfurt for 2 years until i realised my calling was in programming.<br/>
          i switched my studies to <span style={{ color: "#FC766A" }}>{"<data science>"}</span> at justus liebig university, a mixture of <span style={{ color: "#FC766A" }}>{"<mathematics>"}</span> and <span style={{ color: "#FC766A" }}>{"<computer science>"}</span> with a focus on <span style={{ color: "#FC766A" }}>{"<data analysis>"}</span> and <span style={{ color: "#FC766A" }}>{"<data visualization>"}</span>.<br/>
          since then i've worked as <span style={{ color: "#FC766A" }}>{"<web developer>"}</span> and later made the switch to developing<br/> <span style={{ color: "#FC766A" }}>{"<aritificical intelligences> "}</span>
           for a production company in the medical sector.<br/>
          in the beginning of 2022 i switched to a consulting firm as a <span style={{ color: "#FC766A" }}>{"<aritificical intelligences>"}</span> and <span style={{ color: "#FC766A" }}>{"<intelligent proccess automation>"}</span> developer.
          <br/><br/><br/>
          find out more on my <a  href="https://www.linkedin.com/in/henry-hommel-304675234/" target="_blank" style={{ color: "#FC766A" , textDecoration: "underline #FC766A" }}>{"<linked in>"}</a><br/>
        </div>
        
        </div>
      </div>
    </div>

  )
}
